<template>
  <div>
    <v-btn
      icon
      small
      class="rounded-lg"
      @click="dialog = true"
      >
      <v-icon
        color="black"
        >
        mdi-send-outline
      </v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="800"
      transition="dialog-bottom-transition"
      content-class="custom-dialog"
      >
      <profiles
        @share="share"
        ref="profiles"
        ></profiles>

      <v-card
        :ripple="false"
        class="rounded-t-xl rounded-b-0"
        >
        <v-card-title
          class="justify-center pb-0"
          >
          Compartir
        </v-card-title>

        <v-list>
          <v-list-item-group
            color="primary"
            >
            <v-list-item
              @click="$refs.profiles.dialog = true"
              >
              <v-list-item-icon>
                <v-icon>
                  mdi-share
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Enviar a...
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SharePost } from '@/graphql/mutations/posts'

const Profiles = () => import('./Profiles')

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    postId: {
      type: String | Number,
      required: true
    }
  },

  methods: {
    share (id) {
      this.$apollo.mutate({
        mutation: SharePost,
        variables: {
          input: {
            id: this.postId,
            profileId: id
          }
        }
      }).then ( res => {
        this.dialog = false
      })
    }
  },

  components: { Profiles }
}
</script>
